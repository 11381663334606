/**
 * Checks if date is valid
 *
 * @returns true if date is valid, else false
 */
export const isValidDate = (date: Date) => {
  return date instanceof Date;
};

export const formatDate = (date: string) => {
  const newDate = new Date(date);
  return `${newDate.getFullYear()}-${
    newDate.getMonth() + 1
  }-${newDate.getDate()} ${newDate.getHours()}:${newDate.getMinutes()}`;
};

// Calendar strings for react-moment
export const calendarStrings = {
  lastDay: '[Yesterday]',
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  lastWeek: function() {
    const isCurrentYear = this.year() === new Date().getFullYear();
    return isCurrentYear ? 'ddd, MMM D' : '[on] ddd, MMM D, YYYY';
  },
  nextWeek: function() {
    const isCurrentYear = this.year() === new Date().getFullYear();
    return isCurrentYear ? 'ddd, MMM D' : '[on] ddd, MMM D, YYYY';
  },
  sameElse: function() {
    const isCurrentYear = this.year() === new Date().getFullYear();
    return isCurrentYear ? 'ddd, MMM D' : 'MMM D, YYYY';
  }
};

// For message timestamps only
export const timeFormat = {
  sameDay: 'h:mm A' // Shows "3:45 PM"
};
