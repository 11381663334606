import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import Moment from 'react-moment';
import { Link, useRouteMatch } from 'react-router-dom';

import { MessageContext } from '../../contexts/messageContext';
import { GET_ROOM_getRoom_messages_author } from '../../graphql/types/GET_ROOM';
import {
  GET_USER_getUser,
  GET_USER_getUser_professions
} from '../../graphql/types/GET_USER';
import { useScrollToBottom } from '../../hooks/useScrollToBottom';
import { calendarStrings } from '../../utils/dateTime';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import { PrettyContent } from '../Content';
import { MessageContentLoader } from '../ContentLoader';
import MessageReplyForm from './MessageReplyForm';

const DateSeparator = ({ date }: { date: string }) => (
  <div className="date-separator">
    <Moment calendar={calendarStrings}>{date}</Moment>
  </div>
);

interface IMessageItemProps {
  content: string;
  createdAt: string;
  author: GET_ROOM_getRoom_messages_author;
  otherUser: GET_USER_getUser;
}

const MessageItem: FunctionComponent<IMessageItemProps> = ({
  author,
  createdAt,
  content,
  otherUser
}) => {
  const isAuthoredByCurrentUser = author.id !== otherUser.id;
  const avatar = author.avatar
    ? author.avatar && author.avatar.fileUrl
    : otherUser.avatar && otherUser.avatar.fileUrl;

  return (
    <div
      className={`message-item ${
        isAuthoredByCurrentUser ? 'current-user' : ''
      }`}
    >
      {!isAuthoredByCurrentUser && (
        <img
          src={getCompressedImagePath(avatar, 'thumbnail') || avatar}
          alt="profile"
          className="message-avatar"
        />
      )}
      <div className="message-content">
        <PrettyContent
          content={content}
          isLineBreakEnabled={true}
          isCurrentUser={isAuthoredByCurrentUser ? 'current-user' : ''}
        />
        <div className="message-time">
          <Moment format="h:mm A">{createdAt}</Moment>
        </div>
      </div>
      {isAuthoredByCurrentUser && (
        <img
          src={getCompressedImagePath(avatar, 'thumbnail') || avatar}
          alt="profile"
          className="message-avatar"
        />
      )}
    </div>
  );
};

interface IRoomParams {
  roomId?: string;
}

const getLocalDate = (dateString: string) => {
  // Parse the date string and adjust for local timezone
  const date = new Date(dateString.replace(' ', 'T'));
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return new Date(date.toLocaleString('en-US', { timeZone: userTimezone }));
};

const MessageContent = () => {
  const messageListRef = useRef<HTMLDivElement>(null);
  useScrollToBottom(messageListRef);
  const [otherUser, setOtherUser] = useState<any>(null);
  const { actions, currentRoom } = useContext(MessageContext);
  const { params } = useRouteMatch<IRoomParams>();

  // Group messages by date
  const groupMessagesByDate = (messages) => {
    const groups = new Map();

    messages.forEach((message) => {
      // Convert message timestamp to local date using our existing function
      const localDate = getLocalDate(message.createdAt);

      // Get date string in user's timezone (YYYY-MM-DD format)
      const dateKey = localDate.toLocaleDateString('en-CA'); // en-CA gives YYYY-MM-DD format

      if (!groups.has(dateKey)) {
        groups.set(dateKey, []);
      }

      // Store the message with its original createdAt
      groups.get(dateKey).push({
        ...message,
        // Store the localized date for internal use
        localizedDate: localDate
      });
    });

    // Sort messages within each group
    groups.forEach((messagesInGroup) => {
      messagesInGroup.sort((a, b) => {
        const dateA = getLocalDate(a.createdAt);
        const dateB = getLocalDate(b.createdAt);
        return dateA.getTime() - dateB.getTime();
      });
    });
    return groups;
  };

  useEffect(() => {
    if (currentRoom.data && currentRoom.data.getRoom.members.length) {
      setOtherUser(actions.getOtherUser(currentRoom.data.getRoom.members));
    }
  }, [currentRoom.data, currentRoom.loading]); // eslint-disable-line

  useEffect(() => {
    if (params.roomId) {
      actions.handleLoadRoom(params.roomId);
    }
  }, [params.roomId]); // eslint-disable-line

  // Update readAtMessages when messages comes in
  useEffect(() => {
    if (
      currentRoom.data &&
      currentRoom.data.getRoom &&
      currentRoom.data.getRoom.messages.length > 0
    ) {
      actions.handleReadAtMessages(
        currentRoom.data.getRoom.messages.map(m => Number(m.id)),
        Number(currentRoom.data.getRoom.id)
      );
    }
  }, [currentRoom.data]); // eslint-disable-line

  if (currentRoom.loading) {
    return <MessageContentLoader />;
  }

  if (!currentRoom.loading && currentRoom.error) {
    return <div className="notification is-danger">Something went wrong.</div>;
  }

  const renderMessages = () => {
    if (!currentRoom.data?.getRoom?.messages || !otherUser) return null;

    const messageGroups = groupMessagesByDate(
      currentRoom.data.getRoom.messages
    );
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const sortedDates = Array.from(messageGroups.keys()).sort();
    return sortedDates.map((date) => (
      <Fragment key={date}>
        <DateSeparator date={date} />
        {messageGroups.get(date).map((message, idx) => (
          <MessageItem key={idx} {...message} otherUser={otherUser} />
        ))}
      </Fragment>
    ));
  };

  return (
    <Fragment>
      <div className="level page-messages-column-content-header is-bordered-bottom m-b-0">
        {otherUser && otherUser.profile && (
          <div className="level-left">
            <div className="level-item">
              <article className="media align-items-left">
                <figure className="media-left">
                  <img
                    src={getCompressedImagePath(otherUser.avatar.fileUrl, 'thumbnail') || otherUser.avatar.fileUrl}
                    alt="profile"
                    className="image is-inline is-rounded-circle is-48x48"
                    // This helps change the Picture in the messages
                  />
                </figure>
                <div className="media-content">
                  <div className="content">
                    <Link to={`/user/${otherUser.id}`}>
                      <p className="has-text-weight-semibold">
                        {otherUser.profile.name}
                      </p>
                    </Link>
                    <p>
                      {otherUser.professions &&
                        otherUser.professions.map(
                          (
                            profession: GET_USER_getUser_professions,
                            key: number
                          ) => (
                            <span key={key} className="is-small is-light">
                              {(key ? ', ' : '') + profession.name}
                            </span>
                          )
                        )}
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        )}
      </div>
      {currentRoom.data && currentRoom.data.getRoom && otherUser && (
        <div
          className="card-content page-messages-column-card-content p-t-1 p-b-0 p-l-0"
          ref={messageListRef}
        >
          {renderMessages()}
        </div>
      )}
      <MessageReplyForm />
    </Fragment>
  );
};

export default MessageContent;
